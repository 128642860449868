export default {
  data() {
    return {
      validate: {
        priorConsultationNo: { isValid: true, errMsg: "" },
        routeId: { isValid: true, errMsg: "" },
        limitQuantity: { isValid: true, errMsg: "" },
        priorConsultationStartDate: { isValid: true, errMsg: "" },
        priorConsultationEndDate: { isValid: true, errMsg: "" },
        countTermStartDateList: [
            { isValid: true, errMsg: "" }
        ],
        countTermEndDateList: [
            { isValid: true, errMsg: "" }
        ],
        alertValueList: [
            { isValid: true, errMsg: "" }
        ],
      },

      isValidForm: {
      },
    };
  },

  methods: {

    validatesCheck() {
      const v = this.validate;
      let isValidForm = true;
      const form = this.form;
      if ( form.priorConsultationNo && !v.priorConsultationNo.isValid) {
        v.priorConsultationNo.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.routeId.isValid || !form.routeList.length) {
        v.routeId.errMsg = "ルートを選択してください。";
        isValidForm = false;
      }
      if (!v.limitQuantity.isValid || !form.limitQuantity) {
        v.limitQuantity.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.priorConsultationStartDate.isValid || !form.priorConsultationStartDate) {
        v.priorConsultationStartDate.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.priorConsultationEndDate.isValid || !form.priorConsultationEndDate) {
        v.priorConsultationEndDate.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (form.priorConsultationStartDate && form.priorConsultationEndDate) {
        if (form.priorConsultationStartDate > form.priorConsultationEndDate) {
          v.priorConsultationStartDate.errMsg = "開始日と終了日を正しく設定してください。";
          isValidForm = false;
        }
      }
      v.countTermStartDateList.forEach((sd,index) => {
        sd.errMsg = "";
        let startDate = form.countTermList[index].countTermStartDate;
        let endDate = form.countTermList[index].countTermEndDate;
        if (!startDate) {
          sd.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        else if (startDate && endDate && startDate > endDate) {
          sd.errMsg = "開始日と終了日を正しく設定してください。";
          isValidForm = false;
        }
        else if (startDate < form.priorConsultationStartDate || endDate > form.priorConsultationEndDate) {
          sd.errMsg = "協議書期間の期間内に集計期間を設定してください。";
          isValidForm = false;
        }
        else if (form.countTermList.filter( item => item !== form.countTermList[index] ).some(item => startDate <= item.countTermEndDate && startDate >= item.countTermStartDate) ) {
          sd.errMsg = "集計期間は重複しないように設定してください。";
          isValidForm = false;
        }
      })
      v.countTermEndDateList.forEach((ed,index) => {
        let endDate = form.countTermList[index].countTermEndDate;
        if (!endDate) {
          ed.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      })
      v.alertValueList.forEach((av,index) => {
        let alertValue = form.countTermList[index].alertValue;
        if (!alertValue) {
          av.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else if (alertValue < 1 || alertValue > 100) {
          av.errMsg = "1~100 の間の数値を入れてください。";
          isValidForm = false;
        }
      })

      return isValidForm;
    },

    async runValidate() {
      return this.validatesCheck() && this.validateEmptyTerm();
    },
  }

};
