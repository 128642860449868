export default {
  data() {
    return {
      selectingRouteInfo: {},
      selectedRouteInfoList: [],
      routeOptions: [],
      wdsFilePath: "",
      jwnetItemTypeName: "",

      // 一時的に使用用
      // 絞り込み後のルート（画面遷移時はAPI返り値全件を代入）
      narrowedRoutesList: [],
    };
  },
  methods: {

    // ルート選択時
    onChangeRouteHandler() {
      if (this.form.routeList.id) {
        this.selectedRouteInfoList = [];
        for (let id in this.form.routeList.id) {
          const route = this.narrowedRoutesList.filter(
              (r) => r.routeId === this.form.routeList.id[id]
          )[0];
          this.selectingRouteInfo.routeId = route.routeId;
          this.selectingRouteInfo.routeName = route.routeName;
          this.selectingRouteInfo.wasteNameInfo = route.wasteNameInfo;
          this.selectingRouteInfo.generateStoreInfo = route.generateStoreInfo;
          this.selectingRouteInfo.collectCompanyList = route.collectCompanyList;
          this.selectingRouteInfo.disposalCompany = route.disposalCompany;
          this.selectingRouteInfo.disposalSiteAddressInfo = route.disposalSiteAddressInfo;
          this.selectingRouteInfo.entrustId = route.entrustId;
          this.selectedRouteInfoList.push(this.selectingRouteInfo);
          this.form.routeList.push({id: route.routeId});
          this.selectingRouteInfo = {};
        }
      }
      var entrustIdsList = this.selectedRouteInfoList.map((r) => r.entrustId);
      this.form.entrustIds = entrustIdsList.filter(
          (val, index) => entrustIdsList.indexOf(val) == index
      ).join(",");
    },
  },
};
